var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"mobile-ready":"","back-to-enable":false,"main-sidebar":false,"footer":true},scopedSlots:_vm._u([{key:"header-nav",fn:function(){return [_c('div',{staticClass:"inline-block"},[_c('BlockHeaderSwitchManage',{attrs:{"has-permissions":_vm.showHeaderSwitchManage}})],1)]},proxy:true},{key:"main-content",fn:function(){return [_c('PanelUCIdHero',{staticClass:"mt-4 mb-10",attrs:{"user":_vm.user,"version":"v2"}}),_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('quickAccess')}},[_c('div',{staticClass:"grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4"},[(_vm.lastEdition && _vm.lastEdition.key)?_c('button-quick-access',{attrs:{"label":_vm.lastEdition.name,"title":_vm.$t('openLastEdition')},on:{"clicked":function($event){return _vm.$router.push({ name: 'manage-edition', params: { editionKey: _vm.lastEdition.key, view: 'dashboard' } })}}}):_vm._e(),(_vm.lastTournament && _vm.lastTournament.key && _vm.lastTournament.edition_key && _vm.lastTournament.league_key)?_c('button-quick-access',{attrs:{"label":_vm.lastTournament.title[_vm.language],"title":_vm.$t('openLastTournament')},on:{"clicked":function($event){return _vm.$router.push({
              name: 'manage-tournament',
              params: {
                editionKey: _vm.lastTournament.edition_key,
                leagueKey: _vm.lastTournament.league_key,
                tournamentKey: _vm.lastTournament.key,
                view: 'dashboard'
              }
            })}}}):_vm._e(),(false)?_c('button-quick-access',{attrs:{"label":_vm.$t('management'),"title":_vm.$t('configurations'),"icon":"settings"},on:{"clicked":function($event){return _vm.$router.push({ name: 'manage-configurations' })}}}):_vm._e(),(_vm.userPermissions.isGlobalManager)?_c('button-quick-access',{attrs:{"label":_vm.$t('audit'),"title":_vm.$t('activity'),"icon":"activity"},on:{"clicked":function($event){return _vm.$router.push({ name: 'manage-activity' })}}}):_vm._e(),(_vm.userPermissions.isGlobalManager)?_c('button-quick-access',{attrs:{"label":_vm.$t('audit'),"title":_vm.$t('notifications'),"icon":"message-sent"},on:{"clicked":function($event){return _vm.$router.push({ name: 'manage-notifications' })}}}):_vm._e()],1)]),_c('fw-panel',{attrs:{"title":_vm.$t('editions')}},[_c('PanelManageEditions',{on:{"view":function($event){return _vm.$router.push({ name: 'manage-edition', params: { editionKey: $event, view: 'dashboard' } })}}})],1),_c('fw-panel-info',{staticClass:"my-4",attrs:{"debug":"","label":"Data (raw)"}},[_c('json-viewer',{attrs:{"value":{ lastTournament: _vm.lastTournament, lastEdition: _vm.lastEdition }}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }